.image {
    height: 1152px;
    width: 2048px;
}

.image .flat-lay-composition {
    background-color: #50dbf3;
    background-image: url('../resources/Bulb.jpg');
    background-size: cover; /* Scale the image to cover the entire container */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-attachment: fixed; /* Keep the image fixed in place */
    background-position: center center; /* Cente
    */
}
