.label {
    display: inline-flex;
    align-items: center;
    bottom: 0;
    right: 0;
}

@media (min-width: 768px) {
    .label .text-wrapper {
        color: #ffffff;
        font-family: "Montserrat-Bold", Helvetica;
        font-size: 30px;
        font-weight: 700;
        bottom: 0;
        right: 0;
        position: static;
        letter-spacing: 0;
        line-height: normal;
    }
}

@media (max-width: 768px) {
    .label .text-wrapper {
        color: #ffffff;
        font-family: "Montserrat-Bold", Helvetica;
        font-size: 20px;
        font-weight: 700;
        bottom: 0;
        right: 0;
        position: static;
        letter-spacing: 0;
        line-height: normal;
    }
}

.logo {
    width: 50px;
    /* Adjust the width as needed */
    height: 50px;
    /* Adjust the height as needed */
    margin-right: 10px;
    /* Add spacing between the logo and title */
}