@media (max-width: 768px) {
    .box .rectangle {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        /* Take up the full width of the parent container */
        height: 100%;
        /* Take up the full height of the parent container */
        background-color: #000000;
        /* Example background color for the parent */
        position: relative;
        /* Opti */
    }
}

@media (min-width: 768px) {
    .box .rectangle {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 30%;
        /* Take up the full width of the parent container */
        height: 100%;
        /* Take up the full height of the parent container */
        background-color: #000000;
        /* Example background color for the parent */
        position: relative;
        /* Opti */
    }
}